import Vue from 'vue';
import { get } from 'vuex-pathify';
import { mapMutations } from 'vuex';

export type AppLink = {
  text: string;
  icon: string;
  to: string;
  color: string;
  description: string;
  roles?: string[];
  features?: string[];
  namespace: string;
  children?: AppLink[];
  divider?: boolean;
  superuser?: boolean;
};

export default Vue.extend({
  name: 'app-callable',
  computed: {
    eventDialog: {
      get: function () {
        return this.$store.state.app.eventDialog;
      },
      set: function (val) {
        this.SET_EVENT_DIALOG(val);
      }
    },

    drawer: {
      get: function () {
        return this.$store.state.app.drawer;
      },
      set: function (val) {
        this.SET_DRAWER(val);
      }
    },

    rightDrawer: {
      get: function () {
        return this.$store.state.app.rightDrawer;
      },
      set: function (val) {
        this.SET_RIGHT_DRAWER(val);
      }
    },

    mini: {
      get: function () {
        return this.$store.state.app.mini;
      },
      set: function (val) {
        this.SET_MINI(val);
      }
    },
    // rightDrawer: sync('app/rightDrawer'),
    lightCompanyImage: get('app/lightCompanyImage'),
    darkCompanyImage: get('app/darkCompanyImage'),
    miniCompanyImage: get('app/miniCompanyImage'),
    links: get<AppLink[]>('app/links'),
    tabs: get('app/tabs')
  },
  methods: {
    ...mapMutations({
      SET_EVENT_DIALOG: 'app/SET_EVENT_DIALOG',
      SET_DRAWER: 'app/SET_DRAWER',
      TOGGLE_EVENT_DIALOG: 'app/TOGGLE_EVENT_DIALOG',
      SET_RIGHT_DRAWER: 'app/SET_RIGHT_DRAWER',
      SET_MINI: 'app/SET_MINI'
    })
  }
});
