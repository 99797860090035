
import app from '@/mixins/app';
import mixins from '@/utils/mixins';

export default mixins(app).extend({
  name: 'rightDrawer',

  data: () => ({
    menu: false,
    menu2: false,
    max32chars: (v: string) => v.length <= 32 || 'Input too long!',

    items: [
      { text: 'Open', color: 'red', value: 1 },
      { text: 'In Progress', color: 'orange', value: 3 },
      { text: 'Done', color: 'green', value: 5 }
    ] as object[]
  }),

  watch: {
    $route() {
      if (this.rightDrawer && this.$vuetify.breakpoint.mdAndDown)
        this.rightDrawer = false;
    }
  },
  methods: {
    status(status: number) {
      switch (status) {
        case 1:
          return {
            value: 'Open',
            color: 'red'
          };
        case 3:
          return {
            value: 'In Progress',
            color: 'orange'
          };
        case 5:
          return {
            value: 'Done',
            color: 'green'
          };
        default:
          return {
            value: status,
            color: 'secondary'
          };
      }
    }
  }
});
